<template>
  <v-container fluid grid-list-xs>
    <!-- <v-layout row wrap class="pb-12"> -->
    <v-layout row wrap style="padding: 10px">
      <!-- ID CREDIT -->
      <v-flex xs12>
        <v-layout row>
          <v-flex xs6>
            <h4>รหัส {{ HostID + " " + LineID + " " + AgentID + " " + CusID}}</h4>
          </v-flex>
          <v-flex xs6>
            <v-layout row>
              <!-- เนื้อหาทางขวา -->
              <v-flex class="text-right"> เครดิต 0/10000</v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- เส้นคั้น -->
      <v-flex xs12 class="mt-2">
        <v-divider
          horizontal
          color="Gainsboro"
          :inset="false"
          thick
        ></v-divider>
      </v-flex>
      <!-- งวด เวลาปิด -->
      <v-flex xs12 v-if="IsTest == 0">
        <v-layout row class="blue--text text--darken-4 text-center Header">
          <v-flex xs12 class="mt-2">
            <h4 style="font-size: 10vw">{{ getFullPeriodThai(PeriodDT) }}</h4>
          </v-flex>
          <v-flex xs12 class="mb-2">
            <h4 style="font-size: 6vw">ปิดรับ {{ GetCloseTime() }} น.</h4>
            <!-- <h4 style="font-size: 4.5vw">ปิดรับ 15 ธ.ค. 2566 เวลา 14:00 น.</h4> -->
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 v-else>
        <v-layout row class="blue--text text--darken-4 text-center Header">
          <v-flex xs12 class="mt-2">
            <h4 style="font-size: 10vw">งวด ทดลอง</h4>
          </v-flex>
          <v-flex xs12 class="mb-2">
            <h4 style="font-size: 6vw">เปิดให้ ทดสอบ ไม่กินไม่จ่าย</h4>
            <h4 style="font-size: 4.5vw" class="mt-3 red--text text--darken-2">ถ้าจะใช้จริง ให้กดเมนูตั้งค่า เปลี่ยนงวดอื่น</h4>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- เส้นคั้น -->
      <v-flex xs12 class="mt-2">
        <v-divider
          horizontal
          color="Gainsboro"
          :inset="false"
          thick
        ></v-divider>
      </v-flex>
      <!-- เลขอั้น -->
      <v-flex xs12 class="mt-2" v-if="HpType.length == 0">
        <!-- <v-flex xs12 class="mt-2"> -->
        <v-layout row>
          <v-flex xs12>
            <v-icon left color="orange darken-2"> mdi-label </v-icon>
            <span>เลขอั้น - ไม่มี</span>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12 class="mt-2" v-for="(hp_type_item, i) in HpType" :key="i">
        <!-- <v-flex xs12 class="mt-2"> -->
        <v-layout row>
          <v-flex xs12>
            <v-icon left color="orange darken-2"> mdi-label </v-icon>
            <span>เลขอั้น - {{ hp_type_item.PrizeText }}</span>
          </v-flex>
        </v-layout>

        <v-layout
          row
          v-for="(bet_type_item, j) in hp_type_item.BetType"
          :key="j"
        >
          <v-flex xs2 style="border-right: 1px solid Gainsboro">
            <span class="">{{ BT_TITLE_TEXT(bet_type_item.BetType) }}</span>
          </v-flex>
          <v-flex
            xs10
            class="orange--text text--darken-4 font-weight-bold pl-2"
          >
            <span
              class="mr-1"
              v-for="(number_item, j) in bet_type_item.Number"
              :key="j"
            >
              {{ number_item.BetNumber
              }}<v-icon color="orange darken-4" class="mb-1" small>
                mdi-repeat</v-icon
              >
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- เลขเต็ม -->
      <v-flex xs12>
        <v-layout row>
          <v-flex xs12 class="mt-1">
            <v-icon left color="red darken-2"> mdi-label </v-icon>
            <span v-if="FullLimit.length == 0">เลขเต็ม - ไม่มี</span>
            <span v-else>เลขเต็ม</span>
          </v-flex>
        </v-layout>
        <v-layout
          row
          class="mt-1"
          v-for="(full_limit_item, i) in FullLimit.BetType"
          :key="i"
        >
          <v-flex xs2 style="border-right: 2px solid Gainsboro">
            <span>{{ full_limit_item.BetType }} ตัว</span>
          </v-flex>
          <v-flex xs10 class="red--text text--darken-2 font-weight-bold pl-2">
            <span
              class="mr-1"
              v-for="(number_item, j) in full_limit_item.Number"
              :key="j"
              >{{ number_item.BetNumber
              }}<v-icon
                color="red darken-2"
                class="mb-1"
                small
                v-if="number_item.Permute == 1"
              >
                mdi-repeat</v-icon
              >
            </span>
            <!-- 521 522 523 524 525 526 -->
          </v-flex>
        </v-layout>
        <!-- <v-layout row class="mt-1">
            <v-flex xs2 style="border-right: 2px solid Gainsboro">
              <span>2 ตัว</span>
            </v-flex>
            <v-flex xs10 class="red--text text--darken-2 font-weight-bold pl-2">
              21 22 23 24 25 26 27 28 29 30 31 32 33 34 35
            </v-flex>
          </v-layout> -->
      </v-flex>
      <!-- เส้นคั้น -->
      <v-flex xs12 class="mt-2">
        <v-divider
          horizontal
          color="Gainsboro"
          :inset="false"
          thick
        ></v-divider>
      </v-flex>
      <!-- รายการโพย -->
      <v-flex xs12 class="mt-2 text-center teal--text text--darken-4">
        <h3>รายการโพย</h3>
      </v-flex>
      <!-- ทางลัด -->
      <v-flex xs12>
        <v-layout row class="mt-1">
          <v-flex xs3>
            <v-layout column align-center link>
              <v-flex @click="OnAddPage()">
                <v-avatar
                  size="60"
                  class="avatar-with-shadow"
                  style="border: 2px solid #00897b"
                >
                  <v-icon large color="teal darken-1">mdi-note-plus</v-icon>
                </v-avatar>
                <p
                  class="text-body-2 font-weight-bold mt-1 text-center teal--text text--darken-3"
                >
                  สร้างโพย
                </p>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column align-center link>
              <v-flex @click="$router.push('/Pages')">
                <v-avatar
                  size="60"
                  class="avatar-with-shadow"
                  style="border: 2px solid #00897b"
                >
                  <v-icon large color="teal darken-1">mdi-note-search</v-icon>
                </v-avatar>
                <p
                  class="text-body-2 font-weight-bold mt-1 text-center teal--text text--darken-3"
                >
                  ดูโพย
                </p>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column align-center link>
              <v-flex>
                <v-avatar
                  size="60"
                  class="avatar-with-shadow"
                  style="border: 2px solid #00897b"
                >
                  <v-icon large color="teal darken-1"
                    >mdi-clipboard-list-outline</v-icon
                  >
                </v-avatar>
                <p
                  class="text-body-2 font-weight-bold mt-1 text-center teal--text text--darken-3"
                >
                  สรุปเลข
                </p>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column align-center link>
              <v-flex>
                <v-avatar
                  size="60"
                  class="avatar-with-shadow"
                  style="border: 2px solid #00897b"
                >
                  <v-icon large color="teal darken-1"
                    >mdi-magnify-expand</v-icon
                  >
                </v-avatar>
                <p
                  class="text-body-2 font-weight-bold mt-1 text-center teal--text text--darken-3"
                >
                  ค้นหา
                </p>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- สรุป -->
      <v-flex xs12>
        <v-layout row class="mt-1">
          <v-flex xs6>
            <v-card
              color="#e7ecdc"
              class="rounded-lg"
              height="100%"
              elevation="0"
              style="border: 2px solid #00897b"
            >
              <div
                class="d-flex flex-no-wrap justify-space-between"
                style="color: #004d40; font-weight: bold"
              >
                <div>
                  <v-card-title
                    class="pt-1"
                    style="color: #004d40; font-weight: bold"
                  >
                    {{ add_comma(PriceSumAll) }}
                  </v-card-title>

                  <v-card-subtitle style="color: #004d40; font-weight: bold">
                    ยอดรวม
                  </v-card-subtitle>
                </div>
                <span
                  class="mr-5 mt-5"
                  style="color: #004d40; font-weight: bold"
                  absolute
                  >บาท</span
                >
              </div>
            </v-card>
          </v-flex>

          <v-flex xs6>
            <v-card
              color="#e7ecdc"
              class="rounded-lg"
              height="100%"
              elevation="0"
              style="border: 2px solid #00897b"
            >
              <div
                class="d-flex flex-no-wrap justify-space-between"
                style="color: #004d40; font-weight: bold"
              >
                <div>
                  <v-card-title
                    class="pt-1"
                    style="color: #004d40; font-weight: bold"
                  >
                    {{ add_comma(PriceLoyAll) }}
                  </v-card-title>

                  <v-card-subtitle style="color: #004d40; font-weight: bold">
                    ยอดลอย
                  </v-card-subtitle>
                </div>
                <span
                  class="mr-5 mt-5"
                  style="color: #004d40; font-weight: bold"
                  absolute
                  >บาท</span
                >
              </div>
            </v-card>
          </v-flex>

          <v-flex xs6>
            <v-card
              color="#e7ecdc"
              class="rounded-lg"
              height="100%"
              elevation="0"
              style="border: 2px solid #00897b"
            >
              <div
                class="d-flex flex-no-wrap justify-space-between"
                style="color: #004d40; font-weight: bold"
              >
                <div>
                  <v-card-title
                    class="pt-1"
                    style="color: #004d40; font-weight: bold"
                  >
                    {{ add_comma(SendPage) }}
                  </v-card-title>

                  <v-card-subtitle style="color: #004d40; font-weight: bold">
                    โพยส่งแล้ว
                  </v-card-subtitle>
                </div>
                <span
                  class="mr-5 mt-5"
                  style="color: #004d40; font-weight: bold"
                  absolute
                  >ใบ</span
                >
              </div>
            </v-card>
          </v-flex>

          <v-flex xs6>
            <v-card
              class="rounded-lg"
              height="100%"
              :class="GetNonSendPageColor()"
            >
              <div
                class="d-flex flex-no-wrap justify-space-between"
                style="color: gainsboro"
              >
                <div>
                  <v-card-title class="pt-1">
                    {{ add_comma(NonSendPage) }}
                  </v-card-title>

                  <v-card-subtitle> โพยรอส่ง </v-card-subtitle>
                </div>
                <span class="mr-5 mt-5" style="color: gainsboro" absolute
                  >ใบ</span
                >
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- ของตัวเอง จำนวนโพย ส่งแล้ว รอส่ง ตีกลับ ยอดแทง -->
 

      <!-- ให้ขนาดตัวอักษรขยายตามความกว้างของพื้นที่ -->
      <!-- <v-typography class="display-1">
          Your Text
        </v-typography> -->
      <!-- </v-flex> -->
    </v-layout>
  </v-container>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
export default {
  name: "Dashboard",
  mixins: [globalFunctionMixin],
  components: {},
  mounted() {
    this.ckLogin();
    window.scrollTo(0, 0);
    this.fetch_data();
  },
  computed: {
    HpType() {
      let OnlyHP = [];
      this.$store.getters["dashboard/HPList"].forEach((element) => {
        if (element.PrizeText != "ปิดรับ") {
          OnlyHP.push(element);
        }
      });
      return OnlyHP;
    },
    FullLimit() {
      let OnlyFullLimit = [];
      this.$store.getters["dashboard/HPList"].forEach((element) => {
        if (element.PrizeText == "ปิดรับ") {
          OnlyFullLimit.push(element);
        }
      });
      return OnlyFullLimit;
    },
    NonSendPage() {
      return this.$store.getters["dashboard/NonSendPage"];
    },
    SendPage() {
      return this.$store.getters["dashboard/SendPage"];
    },
    PriceSumAll() {
      return this.$store.getters["dashboard/PriceSumAll"];
    },
    PriceLoyAll() {
      return this.$store.getters["dashboard/PriceLoyAll"];
    },
  },
  methods: {
    async fetch_data() {
      let credentials = {
        to: "Dashboard",
        method: "get_dashboard",
        PeriodDT: sessionStorage.getItem("PeriodDT"),
      };
      await this.$store.dispatch("dashboard/TransferData", credentials).then(
        (response) => {
          this.CloseDT = response.Period.CloseTime;
          this.IsTest = parseInt(response.Period.IsTest);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    GetCloseTime() {
      if (this.CloseDT == "") return "";
      const arrCloseDT = this.CloseDT.split(" ");
      const arrCloseTime = arrCloseDT[1].split(":");

      var CloseDate = "";
      //ถ้าวันที่ปิดไม่ตรงกับวันหวยออก
      if (arrCloseDT[0] != this.PeriodDT) {
        CloseDate = this.getAbbrPeriodThai(arrCloseDT[0]) + " เวลา ";
      }

      return CloseDate + arrCloseTime[0] + ":" + arrCloseTime[1];
    },
    GetNonSendPageColor() {
      if (this.NonSendPage == 0) return "grey darken-4";
      return "pink lighten-1";
    },
    BT_TITLE_TEXT(p_BetType) {
      switch (p_BetType) {
        case "1":
          return "3 ตัว";
        case "2":
          return "3 โต็ด";
        case "3":
        case "4":
          return "2 ตัว";

        default:
          break;
      }
    },
    OnAddPage() {
      this.$confirm("กด OK เพื่อยืนยันการเพิ่มโพยใหม่", "ยืนยัน", "question")
        .then(() => {
          this.Sending = true;
          this.doAddPage();
        })
        .catch(() => {});
    },
    async doAddPage() {
      if (this.Sending == false) return;
      if (this.PeriodIsOpen == 0) return;
      let parameter = {
        to: "Pages",
        method: "add_new",
        CusID: "001",
        PeriodDT: this.PeriodDT,
      };

      await this.$store.dispatch("pages/TransferData", parameter).then(
        (response) => {
          //   this.$router.push("/Dashboard");
          console.log(response);
          this.Sending = false;

          this.$router.push({
            path: "Page",
            query: {
              PageNumber: response.PageNumber,
              CusID: "001",
            },
          });
        },
        (error) => {
          switch (error) {
            case "PERIOD_CLOSE":
              this.$alert(
                "ผิดพลาด",
                "งวดนี้ปิดแล้ว ไม่สมารถเพิ่มโพยใหม่ได้",
                "error"
              );
              break;
            case "PAGE_FULL":
              this.$alert(
                "ผิดพลาด",
                "ไม่สามารถเพิ่มโพยใหม่ เพิ่มได้สูงสุดแค่ 20 ใบ",
                "error"
              );
              break;
          }
          this.Sending = false;
        }
      );
    },
  },
  data: () => ({
    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),
    CusID: sessionStorage.getItem("CusID"),
    PeriodDT: sessionStorage.getItem("PeriodDT"),

    CloseDT: "",
    IsTest: 0,
  }),
};
</script>

<style scoped>
.Header {
  /* background: rgb(255, 255, 255);
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(173, 216, 230, 1) 100%
  ); */
}
.HalfPrizeHeader {
  font-weight: bold;
}

.avatar-with-shadow {
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); */
}
</style>
